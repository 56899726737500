import { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { API_BASE_URL } from '../config/api';

export const useFightManager = () => {
  const { eventId } = useParams();
  const [items, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const handleResponse = async (response) => {
    const responseText = await response.text();
  
    let data;
    try {
      data = JSON.parse(responseText);
    } catch (e) {
      throw new Error('Server returned invalid response format');
    }
  
    if (!response.ok) {
      throw new Error(data.message || 'API request failed');
    }
    
    return data;
  };

  const convertToBool = (value) => {
    if (typeof value === 'boolean') return value;
    if (typeof value === 'string') {
      return value === '1';
    }
    return !!value;
  };

  const fetchItems = useCallback(async () => {
    try {
      setIsLoading(true);
      setError(null);
      
      const fightsResponse = await fetch(
        `${API_BASE_URL}/fight/get-by-event.php?event_id=${eventId}`,
        { credentials: 'include' }
      );
      const fightsData = await handleResponse(fightsResponse);
      
      const pausesResponse = await fetch(
        `${API_BASE_URL}/pause/get-by-event.php?event_id=${eventId}`,
        { credentials: 'include' }
      );
      const pausesData = await handleResponse(pausesResponse);

      const fights = (fightsData.fights || []).map(fight => ({
        id: parseInt(fight.id),
        position: parseInt(fight.position),
        itemType: 'fight',
        event_sponsor_index: fight.event_sponsor_index !== null ? 
          parseInt(fight.event_sponsor_index) : null,
        isActive: convertToBool(fight.is_active),
        isCancelled: convertToBool(fight.is_cancelled),
        type: fight.fight_type,
        championship: fight.championship_type,
        weightClass: parseInt(fight.weight_class),
        rounds: parseInt(fight.rounds),
        roundDuration: parseFloat(fight.round_duration_minutes),
        fighter_red_id: parseInt(fight.fighter_red_id),
        fighter_blue_id: parseInt(fight.fighter_blue_id),
        fighterRed: {
          firstName: fight.red_first_name || '',
          lastName: fight.red_last_name || '',
          nickname: fight.red_nickname || '',
          team: fight.red_team || '',
          weight: parseFloat(fight.red_weight) || 0,
          height: parseInt(fight.red_height) || 0,
          age: parseInt(fight.red_age) || 0,
          imageUrl: fight.red_image_url || null,
          serverImageUrl: fight.red_image_url || null,
          titles: fight.red_titles || '',
          statistics: {
            wins: parseInt(fight.red_wins) || 0,
            draws: parseInt(fight.red_draws) || 0,
            losses: parseInt(fight.red_losses) || 0
          }
        },
        fighterBlue: {
          firstName: fight.blue_first_name || '',
          lastName: fight.blue_last_name || '',
          nickname: fight.blue_nickname || '',
          team: fight.blue_team || '',
          weight: parseFloat(fight.blue_weight) || 0,
          height: parseInt(fight.blue_height) || 0,
          age: parseInt(fight.blue_age) || 0,
          imageUrl: fight.blue_image_url || null,
          serverImageUrl: fight.blue_image_url || null,
          titles: fight.blue_titles || '',
          statistics: {
            wins: parseInt(fight.blue_wins) || 0,
            draws: parseInt(fight.blue_draws) || 0,
            losses: parseInt(fight.blue_losses) || 0
          }
        },
        sponsor: fight.sponsor_image_url ? {
          image: fight.sponsor_image_url,
          url: fight.sponsor_website_url
        } : null
      }));

      const pauses = (pausesData.pauses || []).map(pause => ({
        id: parseInt(pause.id),
        position: parseInt(pause.position),
        itemType: 'pause',
        duration: parseInt(pause.duration),
        event_id: parseInt(pause.event_id),
        event_sponsor_index: pause.event_sponsor_index !== null ? 
          parseInt(pause.event_sponsor_index) : null,
        sponsor: pause.sponsor || null
      }));

      const combinedItems = [...fights, ...pauses].sort((a, b) => a.position - b.position);
      setItems(combinedItems);
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  }, [eventId]);

  const saveItems = async (items) => {
    try {
      setIsLoading(true);
      setError(null);
  
      const numericEventId = parseInt(eventId);
      const fights = items.filter(item => item.itemType === 'fight');
      const pauses = items.filter(item => item.itemType === 'pause');
  
      const formData = new FormData();
      
      const fightsData = fights.map((item, index) => {
        const actualPosition = items.findIndex(i => i === item);
  
        if (item.fighterRed?.image instanceof File) {
          formData.append(`fighter_red_image_${index}`, item.fighterRed.image);
        }
        if (item.fighterBlue?.image instanceof File) {
          formData.append(`fighter_blue_image_${index}`, item.fighterBlue.image);
        }
  
        return {
          id: item.id,
          position: actualPosition,
          event_id: numericEventId,
          rounds: parseInt(item.rounds) || 0,
          round_duration_minutes: parseFloat(item.roundDuration) || 0,
          fight_type: String(item.type || '').trim(),
          championship_type: String(item.championship || 'Kein Titelkampf').trim(),
          weight_class: parseInt(item.weightClass) || 0,
          event_sponsor_index: item.event_sponsor_index !== null ? 
            parseInt(item.event_sponsor_index) : null,
          is_active: item.isActive ? "1" : "0",
          is_cancelled: item.isCancelled ? "1" : "0",
          
          fighter_red_id: item.fighter_red_id || null,
          fighter_red_first_name: String(item.fighterRed?.firstName || '').trim(),
          fighter_red_last_name: String(item.fighterRed?.lastName || '').trim(),
          fighter_red_team: String(item.fighterRed?.team || '').trim(),
          fighter_red_weight: parseInt(item.fighterRed?.weight) || 0,
          fighter_red_height: parseInt(item.fighterRed?.height) || 0,
          fighter_red_age: parseInt(item.fighterRed?.age) || 0,
          fighter_red_nickname: String(item.fighterRed?.nickname || '').trim(),
          fighter_red_titles: item.fighterRed?.titles ?? '',
          fighter_red_wins: parseInt(item.fighterRed?.statistics?.wins) || 0,
          fighter_red_draws: parseInt(item.fighterRed?.statistics?.draws) || 0,
          fighter_red_losses: parseInt(item.fighterRed?.statistics?.losses) || 0,
          fighter_red_image_url: item.fighterRed?.serverImageUrl || null,
          
          fighter_blue_id: item.fighter_blue_id || null,
          fighter_blue_first_name: String(item.fighterBlue?.firstName || '').trim(),
          fighter_blue_last_name: String(item.fighterBlue?.lastName || '').trim(),
          fighter_blue_team: String(item.fighterBlue?.team || '').trim(),
          fighter_blue_weight: parseInt(item.fighterBlue?.weight) || 0,
          fighter_blue_height: parseInt(item.fighterBlue?.height) || 0,
          fighter_blue_age: parseInt(item.fighterBlue?.age) || 0,
          fighter_blue_nickname: String(item.fighterBlue?.nickname || '').trim(),
          fighter_blue_titles: item.fighterBlue?.titles ?? '',
          fighter_blue_wins: parseInt(item.fighterBlue?.statistics?.wins) || 0,
          fighter_blue_draws: parseInt(item.fighterBlue?.statistics?.draws) || 0,
          fighter_blue_losses: parseInt(item.fighterBlue?.statistics?.losses) || 0,
          fighter_blue_image_url: item.fighterBlue?.serverImageUrl || null,
        };
      });
  
      formData.append('data', JSON.stringify({
        event_id: numericEventId,
        fights: fightsData
      }));
  
      const fightsResponse = await fetch(`${API_BASE_URL}/fight/update.php`, {
        method: 'POST',
        credentials: 'include',
        body: formData
      });
  
      if (!fightsResponse.ok) {
        const responseText = await fightsResponse.text();
        throw new Error((() => {
          try {
            return JSON.parse(responseText).message;
          } catch (e) {
            return 'Fight update failed';
          }
        })());
      }
  
      const pausesResponse = await fetch(`${API_BASE_URL}/pause/update.php`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          event_id: numericEventId,
          pauses: pauses.map(item => ({
            id: item.id || undefined,
            position: items.findIndex(i => i === item),
            duration: parseInt(item.duration),
            event_id: numericEventId,
            event_sponsor_index: item.event_sponsor_index !== null ? 
              parseInt(item.event_sponsor_index) : null
          }))
        })
      });
  
      if (!pausesResponse.ok) {
        const responseText = await pausesResponse.text();
        throw new Error((() => {
          try {
            return JSON.parse(responseText).message;
          } catch (e) {
            return 'Pause update failed';
          }
        })());
      }
  
      await fetchItems();
      return true;
    } catch (err) {
      setError(err.message);
      return false;
    } finally {
      setIsLoading(false);
    }
  };

  const deleteItem = async (itemId, itemType) => {
    try {
      setIsLoading(true);
      const response = await fetch(
        `${API_BASE_URL}/${itemType}/delete.php?id=${itemId}&event_id=${eventId}`,
        {
          method: 'DELETE',
          credentials: 'include'
        }
      );
      await handleResponse(response);
      await fetchItems();
      return true;
    } catch (err) {
      setError(err.message);
      return false;
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchItems();
  }, [fetchItems]);

  return {
    items,
    setItems,
    isLoading,
    error,
    saveItems,
    deleteItem,
    refetch: fetchItems,
    eventId
  };
};

export default useFightManager;