import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import ImageDropzone from '../../components/ImageDropzone/ImageDropzone';
import { X, Plus, Edit, Trash, Star } from 'lucide-react';
import './Event.css';
import { API_BASE_URL } from '../../config/api';

const Event = () => {
 const { eventId } = useParams();
 const navigate = useNavigate();
 const [isSubmitting, setIsSubmitting] = useState(false);
 const [error, setError] = useState('');
 const [successMessage, setSuccessMessage] = useState('');
 const [users, setUsers] = useState([]);
 const [selectedUsers, setSelectedUsers] = useState([]);
 const [isLoading, setIsLoading] = useState(!!eventId);
 const [isAdmin, setIsAdmin] = useState(false);
 const [additionalSponsors, setAdditionalSponsors] = useState([]);
 const [isModalOpen, setIsModalOpen] = useState(false);
 const [newSponsor, setNewSponsor] = useState({
   id: null,
   url: '',
   image: null,
   file: null
 });
 const [editingIndex, setEditingIndex] = useState(null);

 const [formData, setFormData] = useState({
   name: '',
   description: '',
   date: '',
   time: '12:00',
   organizer_email: '',
   maxfights: '',
   show_fights: false,
   show_event: false 
 });

 const [files, setFiles] = useState({
   event_image: null
 });

 const [previews, setPreviews] = useState({
   event_image: null
 });

 useEffect(() => {
  const checkAccess = async () => {
    if (!eventId) return;
 
    try {
      const response = await fetch(`${API_BASE_URL}/event/get-by-id.php?id=${eventId}`, {
        credentials: 'include' 
      });
 
      if (!response.ok) {
        throw new Error('Event nicht gefunden');
      }
 
      const data = await response.json();
      const eventData = data.event;
      
      const authResponse = await fetch(`${API_BASE_URL}/auth/check-auth.php`, {
        credentials: 'include'
      });
      const authData = await authResponse.json();
      
      const isUserAdmin = authData.authenticated && authData.user.is_admin == 1;
      setIsAdmin(isUserAdmin);
 
      let assignedUsers = [];
      if (eventData.assigned_users) {
        if (typeof eventData.assigned_users === 'string') {
          assignedUsers = eventData.assigned_users.split(',').map(Number);
        } else if (Array.isArray(eventData.assigned_users)) {
          assignedUsers = eventData.assigned_users.map(Number);
        }
      }

      if (!isUserAdmin && !assignedUsers.includes(Number(authData.user.id))) {
        throw new Error('Keine Berechtigung für dieses Event');
      }

      const dateTime = new Date(eventData.date);
      const formattedDate = dateTime.toISOString().split('T')[0];
      const formattedTime = dateTime.toTimeString().split(':').slice(0, 2).join(':');
      const otherSponsors = eventData.sponsors || [];

      const convertToBool = (value) => {
        if (typeof value === 'boolean') return value;
        if (typeof value === 'string') {
          return value === '1';
        }
        return !!value;
      };
 
      setFormData({
        name: eventData.name,
        description: eventData.description || '',
        date: formattedDate,
        time: formattedTime,
        organizer_email: eventData.organizer_email,
        maxfights: eventData.maxfights || '',
        show_fights: convertToBool(eventData.show_fights),
        show_event: convertToBool(eventData.show_event)
      });
 
      if (eventData.image) {
        setPreviews(prev => ({
          ...prev,
          event_image: `${API_BASE_URL}/uploads/${eventData.image}`
        }));
      }
 
      const adjustedSponsors = otherSponsors.map((sponsor, index) => ({
        id: sponsor.id || `temp-id-${index}-${Date.now()}`,
        url: sponsor.website_url,
        image: `${API_BASE_URL}/uploads/${sponsor.image_url}`,
        file: null,
        mainSponsor: sponsor.mainSponsor || false
      }));
            
      setAdditionalSponsors(adjustedSponsors);
      setSelectedUsers(assignedUsers);
 
    } catch (err) {
      setError('Keine Berechtigung für dieses Event');
      navigate('/', { replace: true });
    } finally {
      setIsLoading(false);
    }
  };
 
  if (eventId) {
    checkAccess();
  } else {
    setIsLoading(false);
  }
}, [eventId, navigate]);

 useEffect(() => {
   const checkAuth = async () => {
     try {
      const response = await fetch(`${API_BASE_URL}/auth/check-auth.php`, {
         credentials: 'include'
       });
       const data = await response.json();
       setIsAdmin(data.authenticated && data.user.is_admin == 1);
     } catch (err) {
     }
   };
   checkAuth();
 }, []);

 useEffect(() => {
  const fetchUsers = async () => {
    if (!isAdmin) return;

    try {
      const response = await fetch(`${API_BASE_URL}/user/get-users.php`, {
        credentials: 'include'
      });

      if (!response.ok) {
        throw new Error('Benutzer konnten nicht geladen werden');
      }

      const data = await response.json();
      const nonAdminUsers = (data.users || [])
        .filter(user => user.is_admin !== 1)
        .map(user => ({
          ...user,
          id: Number(user.id)
        }));
      setUsers(nonAdminUsers);
    } catch (err) {
      setError(err.message);
    }
  };

  fetchUsers();
}, [isAdmin]);

 const handleUserSelection = (userId) => {
  userId = Number(userId);
  setSelectedUsers(prev => {
    if (prev.includes(userId)) {
      return prev.filter(id => id !== userId);
    }
    return [...prev, userId];
  });
};

 const normalizeUrl = (url) => {
   if (!url) return url;
   url = url.trim();
   if (!url.match(/^https?:\/\//i)) {
     if (!url.match(/^www\./i)) {
       url = 'www.' + url;
     }
     url = 'https://' + url;
   }
   return url;
 };

 const handleEventImageCropped = (file) => {
   setFiles(prev => ({ ...prev, event_image: file }));
   setPreviews(prev => ({ ...prev, event_image: URL.createObjectURL(file) }));
 };

 const handleSponsorImageCropped = (file) => {
   setNewSponsor(prev => ({
     ...prev,
     image: URL.createObjectURL(file),
     file: file
   }));
 };

 const handleChange = (e) => {
  const { name, value } = e.target;
  if (name === 'name' && !isAdmin) {
    return;
  }
  setFormData(prev => ({
    ...prev,
    [name]: value
  }));
};

 const handleSponsorSubmit = () => {
   if (newSponsor.url && newSponsor.image) {
     const sponsor = {
       ...newSponsor,
       url: normalizeUrl(newSponsor.url),
       id: newSponsor.id || `temp-id-${Date.now()}`,
       file: newSponsor.file,
       mainSponsor: false
     };
     if (editingIndex !== null) {
       setAdditionalSponsors(prev => {
         const updatedSponsors = [...prev];
         updatedSponsors[editingIndex] = sponsor;
         return updatedSponsors;
       });
     } else {
       setAdditionalSponsors(prev => [...prev, sponsor]);
     }
     setNewSponsor({ id: null, url: '', image: null, file: null });
     setEditingIndex(null);
     setIsModalOpen(false);
   }
 };

 const handleEditSponsor = (index) => {
   const sponsor = additionalSponsors[index];
   setNewSponsor({
     id: sponsor.id,
     url: sponsor.url,
     image: sponsor.image,
     file: sponsor.file
   });
   setEditingIndex(index);
   setIsModalOpen(true);
 };

 const handleDeleteSponsor = (index) => {
   setAdditionalSponsors(prev => {
     const updatedSponsors = prev.filter((_, i) => i !== index);
     if (prev[index].mainSponsor) {
       return updatedSponsors.map(sponsor => ({ ...sponsor, mainSponsor: false }));
     }
     return updatedSponsors;
   });
 };

 const handleSetMainSponsor = (index) => {
   setAdditionalSponsors(prevSponsors => {
     const isCurrentlyMain = prevSponsors[index].mainSponsor;
     return prevSponsors.map((sponsor, i) => {
       if (i === index) {
         return { ...sponsor, mainSponsor: !isCurrentlyMain };
       } else {
         return { ...sponsor, mainSponsor: false };
       }
     });
   });
 };

 const handleSubmit = async (e) => {
   e.preventDefault();
   setIsSubmitting(true);
   setError('');
   setSuccessMessage('');

   const dateTime = `${formData.date} ${formData.time}:00`;

   try {
     const submitFormData = new FormData();
     submitFormData.append('name', formData.name);
     submitFormData.append('description', formData.description);
     submitFormData.append('date', dateTime);
     submitFormData.append('organizer_email', formData.organizer_email);
     submitFormData.append('maxfights', formData.maxfights);
     submitFormData.append('show_fights', formData.show_fights ? '1' : '0');
     submitFormData.append('show_event', formData.show_event ? '1' : '0');

     let sponsors = additionalSponsors.map((sponsor, index) => ({
       website_url: sponsor.url,
       image_url: sponsor.file ? undefined : sponsor.image?.split('/').pop(),
       display_order: index,
       mainSponsor: sponsor.mainSponsor || false
     }));

     submitFormData.append('sponsors', JSON.stringify(sponsors));

     if (eventId) {
       submitFormData.append('id', eventId);
     }

     submitFormData.append('users', JSON.stringify(selectedUsers));

     if (files.event_image) {
       submitFormData.append('image', files.event_image);
     } else if (eventId && previews.event_image) {
       submitFormData.append('current_event_image', previews.event_image.split('/').pop());
     }

     sponsors.forEach((sponsor, index) => {
       if (additionalSponsors[index].file) {
         submitFormData.append(`sponsor_image_${index}`, additionalSponsors[index].file);
       }
     });

     const endpoint = eventId
      ? `${API_BASE_URL}/event/update.php`
      : `${API_BASE_URL}/event/create.php`;

     const response = await fetch(endpoint, {
       method: 'POST',
       credentials: 'include',
       body: submitFormData,
     });

     const data = await response.json();

     if (!response.ok) {
       throw new Error(data.message || 'Event konnte nicht gespeichert werden');
     }

     if (eventId) {
       setSuccessMessage('Event wurde erfolgreich aktualisiert!');
     } else {
       setSuccessMessage('Event wurde erfolgreich erstellt!');
       if (data.event_id) {
         navigate(`/event/bearbeiten/${data.event_id}`);
       }
     }

   } catch (err) {
     setError(err.message);
   } finally {
     setIsSubmitting(false);
   }
 };

 if (isLoading) {
   return null;
 }

 const renderAdminContent = (content) => {
   if (!isAdmin) return null;
   return content;
 };

 const handleFightsClick = () => {
   navigate(`/event/bearbeiten/${eventId}/fights`);
 };

 return (
  <div className="event-container">
    <div className="event-content">
      {error && <div className="event-error-message" key="error">{error}</div>}
      {successMessage && <div className="event-success-message" key="success">{successMessage}</div>}

      <form onSubmit={handleSubmit} className="event-form">
        <div className="event-header">
          <h1 className="event-title">
            {eventId ? 'Event bearbeiten' : 'Event erstellen'}
          </h1>
          <div className="event-header-buttons">
            {eventId && (
              <button
                onClick={handleFightsClick}
                className="event-fights-button"
                type="button"
              >
                Kämpfe
              </button>
            )}
            <button
              type="submit"
              className={`event-submit-button ${isSubmitting ? 'event-submitting' : ''}`}
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Wird gespeichert...' : (eventId ? 'Event aktualisieren' : 'Event erstellen')}
            </button>
          </div>
        </div>

        <div className="event-form-group">
          <label htmlFor="name">Eventname*</label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
            className={`event-form-input ${!isAdmin ? 'event-form-input-readonly' : ''}`}
            placeholder="Name des Events"
            readOnly={!isAdmin}
          />
        </div>

        <div className="event-form-group">
          <label htmlFor="description">Beschreibung</label>
          <textarea
            id="description"
            name="description"
            value={formData.description}
            onChange={handleChange}
            className="event-form-input"
            rows="4"
            placeholder="Beschreibung des Events"
          />
        </div>

        <div className="event-form-row">
          <div className="event-form-group">
            <label htmlFor="date">Datum*</label>
            <input
              type="date"
              id="date"
              name="date"
              value={formData.date}
              onChange={handleChange}
              required
              className="event-form-input"
            />
          </div>

          <div className="event-form-group">
            <label htmlFor="time">Uhrzeit*</label>
            <input
              type="time"
              id="time"
              name="time"
              value={formData.time}
              onChange={handleChange}
              required
              className="event-form-input"
            />
          </div>
        </div>

        {renderAdminContent(
          <div className="event-form-group" key="maxfights">
            <label htmlFor="maxfights">Maximale Anzahl Kämpfe*</label>
            <input
              type="number"
              id="maxfights"
              name="maxfights"
              value={formData.maxfights}
              onChange={handleChange}
              required
              min="1"
              className="event-form-input"
              placeholder="z.B. 10"
            />
          </div>
        )}
        
        <div className="toggle-switch-container">
          <label className="toggle-switch">
            <input
              type="checkbox"
              checked={formData.show_event}
              onChange={(e) => setFormData(prev => ({
                ...prev,
                show_event: e.target.checked
              }))}
            />
            <span className="toggle-slider"></span>
          </label>
          <span className="toggle-label">Event öffentlich anzeigen</span>
        </div>
        
        <div className="toggle-switch-container">
          <label className="toggle-switch">
            <input
              type="checkbox"
              checked={formData.show_fights}
              onChange={(e) => setFormData(prev => ({
                ...prev,
                show_fights: e.target.checked
              }))}
            />
            <span className="toggle-slider"></span>
          </label>
          <span className="toggle-label">Kämpfe öffentlich anzeigen</span>
        </div>
        
        <div className="event-form-group">
          <label>Event Bild</label>
          <ImageDropzone
            onImageCropped={handleEventImageCropped}
            previewUrl={previews.event_image}
            label="Klicken oder Event-Bild hierher ziehen"
          />
        </div>

        <div className="event-form-group">
          <label htmlFor="organizer_email">Organisator Email*</label>
          <input
            type="email"
            id="organizer_email"
            name="organizer_email"
            value={formData.organizer_email}
            onChange={handleChange}
            required
            className="event-form-input"
            placeholder="organisator@example.com"
          />
        </div>

        {renderAdminContent(
          <div className="event-form-group" key="users-group">
            <label>Benutzer zuweisen</label>
            <div className="event-users-grid">
            {users.map(user => (
              <label key={`user-${user.id}`} className="event-user-checkbox">
              <input
              type="checkbox"
              checked={selectedUsers.includes(Number(user.id))}
              onChange={() => handleUserSelection(user.id)}
              />
              <span className="event-user-name">{user.username}</span>
              </label>
)             )}
            </div>
          </div>
        )}
      </form>

      <div className="event-content-divider" key="divider" />
      <div className="sponsor-wrapper" key="sponsor-wrapper">
        <div className="sponsor-container">
          <div className="sponsor-header">
            <h2 className="sponsor-title">Sponsoren</h2>
            <button
              className="sponsor-add-button"
              onClick={() => {
                setNewSponsor({ id: null, url: '', image: null, file: null });
                setEditingIndex(null);
                setIsModalOpen(true);
              }}
            >
              <Plus size={20} />
              Sponsor anlegen
            </button>
          </div>

          <div className="sponsor-grid">
            {additionalSponsors.map((sponsor, index) => (
              <div key={`sponsor-${sponsor.id || index}`} className={`sponsor-card ${sponsor.mainSponsor ? 'main-sponsor' : ''}`}>
                <a
                  href={sponsor.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="sponsor-card-link"
                >
                  <img
                    src={sponsor.image}
                    alt="Sponsor"
                    className="sponsor-image"
                  />
                </a>
                {sponsor.mainSponsor && (
                  <div className="main-sponsor-badge">Hauptsponsor</div>
                )}
                <div className="sponsor-card-actions">
                  <button
                    className="sponsor-action-button"
                    onClick={() => handleSetMainSponsor(index)}
                  >
                    {sponsor.mainSponsor ? (
                      <Star size={16} fill="#ffd700" />
                    ) : (
                      <Star size={16} />
                    )}
                  </button>
                  <button
                    className="sponsor-action-button"
                    onClick={() => handleEditSponsor(index)}
                  >
                    <Edit size={16} />
                  </button>
                  <button
                    className="sponsor-action-button"
                    onClick={() => handleDeleteSponsor(index)}
                  >
                    <Trash size={16} />
                  </button>
                </div>
              </div>
            ))}
          </div>

          {isModalOpen && (
            <div className="sponsor-modal-overlay">
              <div className="sponsor-modal">
                <div className="sponsor-modal-header">
                  <h3>{editingIndex !== null ? 'Sponsor bearbeiten' : 'Neuer Sponsor'}</h3>
                  <button
                    className="sponsor-modal-close"
                    onClick={() => {
                      setIsModalOpen(false);
                      setEditingIndex(null);
                    }}
                  >
                    <X size={20} />
                  </button>
                </div>

                <div className="sponsor-modal-content">
                  <div className="sponsor-form-group">
                    <label htmlFor="sponsorUrl">URL</label>
                    <input
                      id="sponsorUrl"
                      type="text"
                      value={newSponsor.url}
                      onChange={(e) => setNewSponsor({ ...newSponsor, url: e.target.value })}
                      placeholder="z.B. fightcards.de"
                    />
                  </div>

                  <div className="sponsor-form-group">
                    <label>Logo</label>
                    <ImageDropzone
                      onImageCropped={handleSponsorImageCropped}
                      previewUrl={newSponsor.image}
                      label="Logo hier ablegen oder klicken"
                    />
                  </div>
                </div>

                <div className="sponsor-modal-footer">
                  <button
                    className="sponsor-button-secondary"
                    onClick={() => {
                      setIsModalOpen(false);
                      setEditingIndex(null);
                    }}
                  >
                    Abbrechen
                  </button>
                  <button
                    className="sponsor-button-primary"
                    onClick={handleSponsorSubmit}
                    disabled={!newSponsor.url || !newSponsor.image}
                  >
                    OK
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  </div>
);
};

export default Event;
