import React from "react";
import './Landing.css';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col"

import qr from '../../assets/qr.jpg';
import card from '../../assets/card.png';
import console from '../../assets/console.png';
import prevideo from '../../assets/pre_mobile.png';


const Landing = () => {
    return (
        <div>
            <section className="landing-masthead">
                <Container className="landing-h-100">
                    <Row className="landing-h-100">
                        <Col lg={7} className="landing-my-auto">
                            <Row>
                                <div className="landing-mx-auto">
                                    <h1>DIGITALE FIGHTCARDS</h1>
                                    <h3>Das nächste Level für Ihre Kampfsportveranstaltung</h3>
                                </div>
                            </Row>
                            <Row>
                                <div className="landing-mx-auto landing-mt-xl">
                                    <a href= "mailto:post@fightcards.de" className="landing-mail">post@fightcards.de</a>
                                </div>
                            </Row>
                        </Col>
                        <Col lg={5} className="landing-masthead-right">
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className="landing-section-info">
                <Container className="landing-h-100">
                    <Row >
                        <Col md={8} className="landing-mx-auto">
                            <h2>Keine Ausdrucke mehr nötig!</h2>
                            <p>Sie haben die Kämpfe bereits in den Druck gegeben und es fällt kurzfristig ein Kampf aus? Sparen Sie sich den Ärger und die Kosten für ein neues Programmheft und ändern in wenigen Minuten Ihre Fightcard.</p>
                        </Col>
                    </Row>
                    <hr></hr>
                    <Row >
                        <Col md={8} className="landing-mx-auto">
                            <h2>Geben Sie Ihren Sponsoren einen angemessenen Platz</h2>
                            <p>Ordnen Sie Ihre Sponsoren den Kämpfen zu. Durch die digitalen Fightcards können die Sponsoren verlinkt werden. Besucher können so direkt zu der Webseite Ihres Sponors gelangen. </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={8} className="landing-mx-auto">
                            <img src={card} alt="fightcard" class="landing-section-image"></img>
                        </Col>
                    </Row>
                    <hr></hr>
                    <Row >
                        <Col md={8} className="landing-mx-auto">
                            <h2>Einfacher Zugang für Ihre Besucher</h2>
                            <p>Ihre Besucher können die Fightcard über einen QR-Code aufrufen. Es muss keine App installiert werden, die Darstellung erfolgt im Browser. Den QR Code können Sie bereits auf die Tickets drucken oder präsentieren den Code auf Klappaufstellern.  </p>
                            <p>Probieren Sie es aus!</p>
                        </Col>
                    </Row>
                    <Row className="">
                        <Col md={8} className="landing-mx-auto">
                            <img src={qr} alt="fightcard" class="landing-section-image"></img>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className="landing-section-features">
                <Container className="landing-h-100">
                    <Row >
                        <Col md={8} className="landing-mx-auto">
                            <h2>Erstellen und bearbeiten Sie die Fightcard bequem über Ihren Webbrowser</h2>
                            <p>Über ihren persönlichen Zugang können Sie die Fightcards einfach zusammenstellen. Sollte sich etwas ändern, könnnen die Fightcards jederzeit angepasst-, oder die Reihenfolge geändert werden. Sogar noch am Veranstaltungsabend.</p>
                        </Col>
                    </Row>
                    <Row className="">
                        <Col md={8} className="landing-mx-auto">
                            <img src={console} alt="Webkonsole" class="landing-section-image"></img>
                        </Col>
                    </Row>
                    <hr></hr>
                </Container>
            </section> 
            <section className="landing-section-mobile">
                <Container className="landing-h-100">
                    <Row className="">
                        <Col md={6} className="landing-mt-xl">
                            <h3>Auf einen Blick</h3>
                            <ul>
                                <li>Keine Ausdrucke</li>
                                <li>Einfacher Zugang über QR-Code</li>
                                <li>Sie können mit der Werbung bereits Monate im Vorraus beginnen</li>
                                <li>Fightcards werden erst nach Freigabe in der Konsole angezeigt</li>
                                <li>Kämpfe jederzeit änderbar</li>
                                <li>Kinderleichte Bearbeitung über Webkonsole</li>
                                <li>Verlinkte Sponsoren</li>
                                <li>Kämpferdetails mit einem Klick auf die Karte einsehbar</li>
                                <li>Zeigen Sie Ihren Besuchern wann Pausen geplant sind</li>
                            </ul>
                        </Col>
                        <Col md={2} className="landing-mx-auto landing-mt-xl">
                            <video max-width="360" poster={prevideo} className="landing-video" autoPlay muted loop>
                                    <source src="/Video/fightcards_mobile_portrait.mp4" type="video/mp4"/>
                                    <img src={prevideo} id="imgPhoneVertical" alt="Fight Card Mobil Portrait"/>
                            </video>
                        </Col>                  
                    </Row>
                    <Row className="landing-mx-auto landing-mt-xl">
                        <h2>Kostenlose Nutzung der Fightcards für das Jahr 2025!</h2>
                    </Row>
                    <Row>
                        <div className="landing-mx-auto landing-mt-xl">
                            <a href= "mailto:post@fightcards.de" className="landing-mail">post@fightcards.de</a>
                        </div>
                    </Row>
                </Container>
            </section> 
        </div>
        
    );
}


export default Landing;