import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import FightCard from '../../components/FightCard/Fightcard';
import Pause from '../../components/Pause/Pause';
import { useFightManager } from '../../hooks/useFightManager';
import { API_BASE_URL } from '../../config/api';
import './Fight.css';

const Fight = () => {
  const navigate = useNavigate();
  const { eventId } = useParams();
  const [eventSponsors, setEventSponsors] = useState([]);
  const [eventName, setEventName] = useState('');

  const {
    items,
    isLoading,
    error,
  } = useFightManager();

  useEffect(() => {
    const fetchEventDetails = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/event/get-by-id.php?id=${eventId}`, {
          credentials: 'include'
        });
        
        if (response.ok) {
          const data = await response.json();
          if (data.event) {
            if (data.event.sponsors) {
              setEventSponsors(data.event.sponsors);
            }
            if (data.event.name) {
              setEventName(data.event.name);
            }
          }
        }
      } catch (error) {
        console.error('Error fetching event details:', error);
      }
    };

    fetchEventDetails();
  }, [eventId]);

  useEffect(() => {
    if (!isLoading && items.length === 0) {
      navigate(`/event/${eventId}`);
    }
  }, [isLoading, items, navigate, eventId]);

  if (isLoading) {
    return <div>Laden...</div>;
  }

  if (error) {
    return <div>Fehler: {error}</div>;
  }

  return (
    <div className="fight-container">
      <h1 className="fight-title">{eventName}</h1>
      <div className="fight-items">
        {items.map((item, index) => (
          <div key={`item-${index}`}>
            {item.itemType === 'fight' ? (
              <FightCard
                fighterRed={{
                  id: item.fighter_red_id,
                  ...item.fighterRed
                }}
                fighterBlue={{
                  id: item.fighter_blue_id,
                  ...item.fighterBlue
                }}
                fight={{
                  type: item.type,
                  weightClass: item.weightClass,
                  rounds: item.rounds,
                  roundDuration: item.roundDuration,
                  championship: item.championship,
                  isActive: item.isActive,
                  isCancelled: item.isCancelled,
                  event_sponsor_index: item.event_sponsor_index
                }}
                eventSponsors={eventSponsors}
              />
            ) : (
              <Pause
                duration={item.duration}
                event_sponsor_index={item.event_sponsor_index}
                eventSponsors={eventSponsors}
              />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Fight;